import { useEffect, useState } from "react";
import RouterPath from "./Components/RouterPath";

function App() {

  const PLAY_STORE = "https://play.google.com/store/apps/details?id=com.shop.hutti";
  // const PLAY_STORE = "https://play.google.com/store/apps/details?id=com.shop.hutti&referrer=utm_source%3Dgoogle%26utm_medium%3Dreferral%26utm_campaign%3D{uid}"
  const [subDomain, setSubDomain] = useState(null);

  useEffect(() => {
    const host = window.location.host;
    setSubDomain(host.split('.')[0]);

  }, [])
 
  if (subDomain === 'app') {

    // console.log("OS ", navigator.userAgent);
    // if (navigator.userAgent.match(/Android/i)) {
    if (window.location.pathname.split('/')[1] === 'refHu') {
      const win = window.open(PLAY_STORE + '&referrer=utm_medium%3Dreferral%26ref_uid%3D' + window.location.pathname.split('/')[2], '_self');
      if (win !== null) win.focus();
    }
    // }
  } else {
    return <RouterPath />;
  }
}

export default App;
